<template>
	<div class="device-develop">
		<Common-Shell type="deviceDevelop" :search-option="searchOption"></Common-Shell>
	</div>
</template>

<script>
import CommonShell from '../comps/CommonShell.vue'

export default {
	name: 'deviceDevelop',
	components: { CommonShell },
	props: {},
	data() {
		return {}
	},
	computed: {
		searchOption() {
			return {}
		},
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {},
}
</script>
<style lang="scss" scoped>
.device-develop {
	height: 100%;
}
</style>
